import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import { processGeojson } from "kepler.gl/processors";
import { Button } from "antd";
import cn from "classnames";
import styles from "./home.module.css";
import { DateRange, FiltersData } from "../../interfaces/filters";
import { Search } from "./search";
import { Kepler } from "../../pages/keplergl";
import SideBarArrow from "../../images/side-bar-arrow.svg";
import MapIcon from "../../images/map-icon.svg";
import CloseImage from "../../images/close-icon-black.svg";
import { createBindDataAction, setFilterActionCreator } from "./search/reducer";
import { FullScreenLoading } from "../../components/loading";
import { createOpenModalEventAction } from "./event/reducer";
import { EventModal } from "./event";
import { loadEventsData, LoadingCallbackArgument } from "../../utils/tools";
import { SubmitEvent } from "./submitEvent";
import { SubmitFormFields } from "./submitEvent/submitEvent";
import { RootState } from "../../store";
import { AdaptiveModal } from "../../components/adaptiveModal";
import { Layout } from "../../components/layout";
import { About } from "../about";

interface DataLoadingState {
  loading: boolean;
  message: string;
}

const getResource = async (): Promise<string> => {
  const resourceUrl = process.env.REACT_APP_RESOURCE_URL;

  if (resourceUrl === undefined) {
    throw new Error("REACT_APP_RESOURCE_URL must be set");
  }

  return resourceUrl;
};

const labelColors: Record<string, string> = {
  Default: "#344054",
  Classic: "#344054",
  Earth: "#FFFFFF",
};

export const Home = () => {
  const [loading, setLoading] = useState<DataLoadingState>({
    loading: true,
    message: "",
  });
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [filters, setFilters] = useState<FiltersData>();
  const event = useSelector((state: RootState) => state.event);
  const { initialStateChanged, searchEngine } = useSelector((state: RootState) => state.searchEngine);
  const { currentMap } = useSelector((state: RootState) => state.keplerMapState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (event != null) {
      navigate(`/event/${event.uniqueIndex}${window.location.search}`);
    }
  }, [event]);

  useEffect(() => {
    const loadFunction = (url: string) => {
      loadEventsData(url, (message: LoadingCallbackArgument) => {
        setLoading((prevDataLoading) => ({ ...prevDataLoading, message: message.message }));
      })
        .then((data: any) => {
          dispatch(createBindDataAction(processGeojson(data)));
          setLoading((prevDataLoading) => ({ ...prevDataLoading, loading: false }));
        })
        .catch((err) => {
          console.log("loadEventsData function error\n" + err);
        });
    };

    getResource().then((url) => {
      loadFunction(url);
    });
  }, [setLoading, dispatch]);

  useEffect(() => {
    if (!filters || loading.loading) {
      return;
    }

    const dateRange = filters.dateRange !== null ? filters.dateRange : getDefaultDateRange();

    dispatch(
      setFilterActionCreator({
        ...filters,
        dateRange: dateRange,
      })
    );
  }, [loading, filters, initialStateChanged]);

  const onSelectEvent = (eventObj: any) => {
    dispatch(createOpenModalEventAction(eventObj));
  };

  const getDefaultDateRange = (): DateRange => {
    return searchEngine.getDateRange(searchEngine.proxyData);
  };

  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const filtersOnChange = (newFilters: FiltersData) => {
    setFilters(newFilters);
  };

  return (
    <>
      {loading.loading ? (
        <FullScreenLoading>
          <span>{loading.message}</span>
        </FullScreenLoading>
      ) : (
        <AboutMapPopup />
      )}

      <div className={cn(styles.dashboard, sideBarOpen && styles.dashboardSideBarOpen)}>
        <div className={styles.content}>
          <div className={cn(styles.filtersContainer, !sideBarOpen && styles.filtersContainerClose)}>
            <Search onFilters={filtersOnChange} onSelectEvent={onSelectEvent} />
            <button
              onClick={toggleSideBar}
              className={cn(styles.toggleSideBarBtn, sideBarOpen && styles.toggleSideBarBtnOpen)}
            >
              <img src={SideBarArrow} className={cn(!sideBarOpen && styles.sideBarImageClose)} alt="Toggle side bar" />
            </button>
          </div>
          <p
            style={{ color: labelColors[currentMap.name] }}
            className={cn(styles.mapLabel, !sideBarOpen && styles.mapLabelFull)}
          >
            Eyes on Russia Map
          </p>
          <div className={cn(styles.keplerMapContainer, sideBarOpen ? styles.keplerMin : styles.keplerFull)}>
            <Kepler />
          </div>
        </div>
      </div>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/about" element={<About />} />
        </Route>
        <Route
          path="event/:id"
          element={
            <div className={styles.eventModal}>
              <EventModal />
            </div>
          }
        />
      </Routes>
    </>
  );
};

const modalLocalStorageKey = "info-modal";

const AboutMapPopup = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const opened = localStorage.getItem(modalLocalStorageKey);
    if (!opened) {
      setOpen(true);
      localStorage.setItem(modalLocalStorageKey, "true");
    }
  }, []);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <AdaptiveModal
        open={open}
        close={close}
        children={
          <div className={styles.aboutPopup}>
            <div className={styles.aboutPopupHeader}>
              <div className={styles.mapIconBlock}>
                <img src={MapIcon} alt="Map icon" className={styles.mapIcon} />
              </div>
              <div className={styles.aboutPopupHeaderTitle}>
                <h1 className={styles.aboutPopupTitle}>The Eyes on Russia Map</h1>
                <p className={styles.aboutPopupTitleSub}>From The Centre for Information Resilience</p>
              </div>
            </div>
            <hr className={styles.divideLine} />
            <div className={styles.aboutPopupContent}>
              <p>
                In January 2022, CIR launched the Eyes on Russia project to collect and verify videos, photos, satellite
                imagery, or other media related to Russia's invasion of Ukraine.
              </p>
              <p>
                The goal of the project is simple: to provide the world with timely and reliable information concerning
                the impact of Russia's invasion on Ukraine and its people.
              </p>
              <p>
                Coordinating the collective work of the wider open source community, including Bellingcat and
                GeoConfirmed, with support from Advance Democracy, Inc., the project produced a database of verified
                information covering the events of Russia's invasion. The Eyes on Russia Map was created to facilitate
                the public's access and understanding of this information. Developed in collaboration with C4ADS, the
                map draws on the database of videos, photos, satellite imagery or other media that the project has
                collected and verified.
              </p>
              <p>
                More information about us and how to use the map is available
                <a href="/about" target="_blank" onClick={close}>
                  {" "}
                  at the About page.
                </a>
              </p>
              <p>
                We are grateful to the people on the ground in Ukraine who film and document these events. Without their
                bravery, the world would be unaware of the full extent of what's happening in the war.
              </p>
            </div>
            <div className={styles.aboutPopupFooter}>
              <span className={styles.footerInfoText}>
                <a className={styles.footerLink} onClick={close}>
                  Explore the Eyes on Russia Map
                </a>
              </span>
            </div>
          </div>
        }
      />
      <button onClick={close} className={cn(styles.closeAboutPopup, !open && styles.closeAboutPopupHidden)}>
        <img src={CloseImage} alt="Close popup about" />
      </button>
      {/* Here I move this button outside the adaptive modal block, in order to avoid the unwanted animation caused by antd design */}
    </>
  );
};

// I will leave the "Submit Event" code until the final confirmation that this functionality is not needed.
const SubmitEventContainer = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = (fields: SubmitFormFields) => {
    setOpen(false);
    console.log(fields);
  };

  return (
    <>
      <Button className={styles.submitButton} type="primary" onClick={() => setOpen(true)}>
        Submit Event
      </Button>
      <SubmitEvent onSubmit={onSubmit} open={open} onClose={() => setOpen(false)} />
    </>
  );
};
