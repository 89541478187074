import { AnyAction } from "redux";
import { SearchEngine } from "../../../utils/packages/searchEngine";
import { searchEngineActions } from "../../../constants/actionTypes";

export type DatabaseType = "disinformationDB" | "verifiedDB" | "both";

interface searchEngineState {
  searchEngine: SearchEngine;
  filteredIndexes: number[];
  initialStateChanged: boolean;
  typeOfDataBase: DatabaseType;
  isReady: boolean;
}

const initialState: searchEngineState = {
  searchEngine: new SearchEngine(),
  filteredIndexes: [],
  initialStateChanged: false,
  typeOfDataBase: "verifiedDB",
  isReady: false,
};

const searchFilterReducer = (state = initialState, action: AnyAction): searchEngineState => {
  const { type, payload } = action;

  switch (type) {
    case searchEngineActions.SET_FILTERS_ACTION:
      state.searchEngine.setFilters(payload);
      const indexes = state.searchEngine.filter();
      state.filteredIndexes = indexes;

      return { ...state };
    case searchEngineActions.BIND_DATA_ACTION:
      state.searchEngine.bindData(payload);
      state.filteredIndexes = state.searchEngine.getFilteredIndexes();
      state.isReady = true;

      return { ...state };
    case searchEngineActions.CHANGE_INITIAL_STATE:
      return { ...state, initialStateChanged: true };
    case searchEngineActions.CHANGE_TYPE_OF_DATABASE:
      return { ...state, typeOfDataBase: action.payload };
    default:
      return state;
  }
};

export const setInitialStateChanged = () => {
  return {
    type: searchEngineActions.CHANGE_INITIAL_STATE,
  };
};

export const createBindDataAction = (payload: any): AnyAction => {
  return {
    type: searchEngineActions.BIND_DATA_ACTION,
    payload: payload,
  };
};

export const setFilterActionCreator = (payload: any): AnyAction => {
  return {
    type: searchEngineActions.SET_FILTERS_ACTION,
    payload: payload,
  };
};

export default searchFilterReducer;
