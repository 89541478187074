import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import cn from "classnames";
import { SearchOutlined, SlidersOutlined, CloseOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Checkbox, Input } from "antd";
import { SelectProps } from "antd/es/select";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import styles from "./search.module.css";
import { ReactComponent as MapPointIcon } from "../../../images/map-point-icon.svg";
import CloseIcon from "../../../images/close-icon.svg";
import { categoryList, sectorAffected } from "./variables";
import { CheckboxGroup } from "./components/checkboxGroup";
import { CustomRangeDatePicker } from "./components/customDateRangePicker";
import { Events } from "../events";
import { FoundPlace } from "../../../utils/packages/searchEngine/interfaces";
import { DateRange, FiltersData } from "../../../interfaces/filters";
import { RootState } from "../../../store";
import { Event } from "../../../interfaces/events";
import { disableObjectKeys } from "../../../utils/tools";
import { setInitialStateChanged } from "./reducer";
import { getSearchParams, getSearchParamsFromFilters } from "./packages/searchParamsParser";
import { keplerExtension } from "../../../constants/actionTypes";
import { DEFAULT_FILTERS_STATE } from "../../../utils/packages/searchEngine/searchEngine";

type ChosenOption = [string, number, string, string | undefined];
interface FiltersProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
}
interface SearchProps {
  onSelectEvent: (eventObj: Event) => void;
  onFilters: (filters: FiltersData) => void;
}

const searchResult = (items: FoundPlace[], query: string, onQuerySearch?: () => void) => {
  const queryLength = query.length;

  const firstItem = {
    value: ["", -1, "", ""],
    label: (
      <div
        onClick={onQuerySearch}
        className={cn(styles.autocompleteFoundSearchItem, styles.underline, styles.firstAutoCompleteItem)}
      >
        <div className={cn(styles.foundSearchItemInfo, styles.firstAutoCompleteItemInfo)}>
          <SearchOutlined className={styles.searchIcon} />
          <div className={styles.foundSearchItemInfoText}>
            <p className={styles.simpleDescriptionText}>Keyword search for "{query}"</p>
          </div>
        </div>
      </div>
    ),
  };

  const options = items.map((item) => {
    let name: { tag: string; value: string }[] = [];

    if (item.description) {
      name = [{ tag: "p", value: item.name }];
    } else {
      name = [
        { tag: "p", value: item.name.slice(0, item.index) },
        { tag: "b", value: item.name.slice(item.index, item.index + queryLength) },
        { tag: "p", value: item.name.slice(item.index + queryLength) },
      ];
    }

    return {
      value: [item.name, item.firstFoundIndex, item.filterName, item.description],
      label: (
        <div className={cn(styles.autocompleteFoundSearchItem, styles.underline)}>
          <div className={styles.foundSearchItemInfo}>
            <MapPointIcon className={styles.foundSearchItemInfoImg} />
            <div className={styles.foundSearchItemInfoText}>
              {name.map((item, index) => {
                return item.tag === "b" ? (
                  <b className={styles.boldDescriptionText} key={index}>
                    {item.value}
                  </b>
                ) : (
                  <p className={styles.simpleDescriptionText} key={index}>
                    {item.value}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      ),
    };
  });

  // @ts-ignore
  return query === "" ? options : [firstItem].concat(options);
};

const initOnChangeHandlerDecorator = (changeDefaultState: () => void) => {
  const onChangeHandlerDecorator = (func: any) => {
    let defaultFiltersStateChanged: boolean = false;
    return function () {
      if (!defaultFiltersStateChanged) {
        defaultFiltersStateChanged = true;
        changeDefaultState();
      }
      // @ts-ignore
      func(...arguments);
    };
  };

  return onChangeHandlerDecorator;
};

export const Search = ({ onSelectEvent, onFilters }: SearchProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchEngine, isReady: searchEngineIsReady } = useSelector((state: RootState) => state.searchEngine);
  const [filters, setFilters] = useState<FiltersData>(getSearchParams(searchParams));
  const [openFilterBlockState, setOpenFilterBlock] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<[string, boolean]>(["", false]);
  const [fullMode, setFullMode] = useState<boolean>(false);
  const [eventsOpen, setEventsOpen] = useState<{ open: boolean }>({ open: false });
  const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [chosenOption, setChosenOption] = useState<ChosenOption | null>(null);
  const dispatch = useDispatch();

  const onChangeHandlerDecorator = useMemo(() => {
    return initOnChangeHandlerDecorator(() => {
      dispatch(setInitialStateChanged());
    });
  }, []);

  useEffect(() => {
    onFilters(filters);
  }, []);

  useEffect(() => {
    if (!autoCompleteOpen) {
      return;
    }

    const listener = (e: KeyboardEvent) => {
      if (searchInputValue[0].length === 0) {
        return;
      }
      if (e.key === "Enter") {
        updateFilters({ ...filters, query: searchInputValue[0], chosenOption: "null,null,null" });
        setAutoCompleteOpen(false);
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [autoCompleteOpen, searchInputValue, filters]);

  useEffect(() => {
    if (!searchEngine.filtersInitialized) {
      return;
    }

    const exceptedKeys = ["chosenOption"];
    if (
      JSON.stringify(disableObjectKeys(searchEngine.filters, exceptedKeys)) !==
      JSON.stringify(disableObjectKeys(filters, exceptedKeys))
    ) {
      const newFilters = { ...searchEngine.filters };
      newFilters["chosenOption"] = !chosenOption ? "null,null,null" : chosenOption[2];

      setFilters(newFilters);
    }
  }, [searchEngine.filters]);

  useEffect(() => {
    if (searchEngine.ready) {
      let option = filters.chosenOption
        .split(",")
        .filter((item) => item !== "null" && item !== "undefined")
        .join(", ");

      if (option.length === 0) {
        option = filters.query;
      }

      setSearchInputValue([option, true]);
    }
  }, [searchEngine.ready]);

  const clearAutoComplete = onChangeHandlerDecorator(() => {
    setEventsOpen({ open: true });
    setFullMode(false);
    updateFilters({ ...filters, chosenOption: "null,null,null", query: "" });

    // @ts-ignore
    onSearchHandler("");
    setChosenOption(null);
    setSearchInputValue(["", false]);
    searchEngine.saveCurrentZoomAndCoordinates = false;
  });

  const chunkLoader = useMemo(() => {
    if (!searchInputValue) {
      return;
    }
    const resultGenerator = searchEngine.searchPlaces(searchInputValue[0], 10);
    const getChunkResults = resultGenerator();

    return () => {
      const chunk = getChunkResults.next();
      const value = chunk.value as FoundPlace[];

      if (searchInputValue[1]) {
        const found = value.find((item) => item.name === searchInputValue[0]);
        if (found) {
          setEventsOpen({ open: true });
          setChosenOption([found.name, found.firstFoundIndex, found.filterName, found.description]);
        }
      }

      if (chunk.done) {
        return;
      }
      return value;
    };
  }, [searchInputValue]);

  useEffect(() => {
    if (!chunkLoader) {
      return;
    }

    const chunk = chunkLoader();
    if (!chunk) {
      return;
    }

    const searchByQuery = () => {
      setAutoCompleteOpen(false);
      updateFilters({ ...filters, chosenOption: "null,null,null", query: searchInputValue[0] });
    };
    // @ts-ignore
    setOptions(searchResult(chunk, searchInputValue[0], searchByQuery));
  }, [chunkLoader]);

  const onScrollDown = (e: any) => {
    // here is any type because i didn't find type in antDesign documentation
    const { height } = e.target.getBoundingClientRect();
    const scrollHeight = e.target.scrollHeight;
    const scrollTop = e.target.scrollTop;

    if (scrollHeight - (scrollTop + height) < 100) {
      if (!chunkLoader) {
        return;
      }
      const chunk = chunkLoader();
      if (chunk) {
        // @ts-ignore
        setOptions(options.concat(searchResult(chunk, searchInputValue[0])));
      }
    }
  };

  const closeFilterBlock = () => {
    setOpenFilterBlock(false);
  };

  const openFilterBlock = () => {
    setOpenFilterBlock(true);
  };

  const onFocus = () => {
    setAutoCompleteOpen(true);
  };

  const onBlur = () => {
    setAutoCompleteOpen(false);
  };

  const updateFilters = (filters: FiltersData) => {
    setSearchParams(getSearchParamsFromFilters(filters));
    setFilters(filters);
    onFilters(filters);
  };

  const dateRangeOnChange = onChangeHandlerDecorator((dateRange: DateRange) => {
    updateFilters({ ...filters, dateRange });
  });

  const categoriesOnChange = onChangeHandlerDecorator((checkBoxItems: string[]) => {
    updateFilters({ ...filters, categories: checkBoxItems });
  });

  const sectorAffectedOnChange = onChangeHandlerDecorator((checkBoxItems: string[]) => {
    updateFilters({ ...filters, sectorAffected: checkBoxItems });
  });

  const checkBoxEventsOnChange = onChangeHandlerDecorator((e: CheckboxChangeEvent) => {
    updateFilters({ ...filters, onlyEventsMapFrame: e.target.checked });
    dispatch({
      type: keplerExtension.UPDATE_KEPLER_GL,
    });
  });

  const closeFullModeEventsWindow = () => {
    setEventsOpen({ open: true });
    setFullMode(false);
  };

  const fullModeCallback = (enable: boolean) => {
    setFullMode(enable);
  };

  const onSearchHandler = onChangeHandlerDecorator((query: string) => {
    setSearchInputValue([query, false]);
    if (!autoCompleteOpen) {
      setAutoCompleteOpen(true);
    }
  });

  const onSelectOptionHandler = onChangeHandlerDecorator((option: ChosenOption) => {
    if (option[1] === -1) {
      return;
    }
    setEventsOpen({ open: true });
    searchEngine.saveCurrentZoomAndCoordinates = false;

    updateFilters({ ...filters, chosenOption: option[2], query: !option[3] ? "" : searchInputValue[0] });
    setChosenOption(option);
    setSearchInputValue([option[0], false]);
    setAutoCompleteOpen(false);
  });

  const resetFilters = () => {
    clearAutoComplete();
    setFilters({ ...DEFAULT_FILTERS_STATE });
    onFilters({ ...DEFAULT_FILTERS_STATE });
    setSearchParams(getSearchParamsFromFilters({ ...DEFAULT_FILTERS_STATE }));
    setAutoCompleteOpen(false);
  };

  const dateRangeBoundaries = useMemo(
    () => searchEngine.getDateRange(searchEngine.data),
    [searchEngine, searchEngineIsReady]
  );

  return (
    <>
      <div className={cn(styles.container, fullMode && styles.fullModeContainer)}>
        <div className={styles.inputBox}>
          <AutoComplete
            value={searchInputValue[0]}
            open={autoCompleteOpen}
            style={{ width: "100%" }}
            onSearch={onSearchHandler}
            onFocus={onFocus}
            onBlur={onBlur}
            onSelect={onSelectOptionHandler}
            onPopupScroll={onScrollDown}
            options={options}
          >
            <Input
              className={styles.searchInput}
              addonBefore={<SearchOutlined className={styles.searchIcon} />}
              addonAfter={
                searchInputValue[0].length !== 0 ? (
                  <CloseOutlined className={styles.clearIcon} onClick={clearAutoComplete} />
                ) : null
              }
              placeholder="Search locations or keywords"
            />
          </AutoComplete>
        </div>
        <FilterWrapper open={openFilterBlockState} close={closeFilterBlock}>
          <div className={styles.searchFilterBlock}>
            <CustomRangeDatePicker
              defaultDateRange={filters.dateRange}
              onChange={dateRangeOnChange}
              dateRangeBoundaries={dateRangeBoundaries}
            />
            <div>
              <div className={styles.searchFilterSelectBlock}>
                <CheckboxGroup
                  options={categoryList}
                  checkedOptions={filters.categories}
                  placeholder="All categories"
                  name="Categories"
                  onChecked={categoriesOnChange}
                />
                <CheckboxGroup
                  options={sectorAffected}
                  checkedOptions={filters.sectorAffected}
                  placeholder="Sector affected"
                  name="Sector affected"
                  onChecked={sectorAffectedOnChange}
                />
              </div>
            </div>
            <div className={styles.footerFilters}>
              <Checkbox
                checked={filters.onlyEventsMapFrame}
                className={styles.mapFrameEvents}
                onChange={checkBoxEventsOnChange}
              >
                Only Events in Map Frame
              </Checkbox>
              <button onClick={resetFilters} className={styles.resetButton}>
                Reset
              </button>
            </div>
          </div>
        </FilterWrapper>
        <Button
          className={cn(styles.searchHeaderBtn, styles.filtersButton)}
          icon={<SlidersOutlined />}
          onClick={openFilterBlock}
        />
        {fullMode && (
          <Button
            className={styles.searchHeaderBtn}
            icon={<img className={styles.buttonImageStyle} src={CloseIcon} alt="Close button" />}
            onClick={closeFullModeEventsWindow}
          />
        )}
      </div>
      <Events
        onSelectEvent={onSelectEvent}
        region={chosenOption}
        fullModeCallback={fullModeCallback}
        open={eventsOpen}
      />
    </>
  );
};

const FilterWrapper = ({ open, close, children }: FiltersProps) => {
  return (
    <div className={cn(styles.filterContainer, open && styles.filterWrapperOpen)}>
      <div className={styles.filterContent}>
        <div className={styles.filterHeaderWrapper}>
          <div className={styles.filterHeader}>
            <span className={styles.filterTitle}>Filter</span>
            <Button
              className={styles.filterCloseBtn}
              icon={<img src={CloseIcon} alt="Close filter drawer" />}
              onClick={close}
            />
          </div>
        </div>
        <div className={styles.filterBody}>{children}</div>
      </div>
    </div>
  );
};
