import React, { useEffect, useState, useMemo, useRef } from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import { debounce } from "lodash";
import cn from "classnames";
import { batch, useDispatch, useSelector } from "react-redux";
import styles from "./events.module.css";
import { AbsoluteLoading } from "../../../components/loading/loading";
import { useEffectWithMemory } from "../../../hooks/useEffectWithMemory";
import { RootState } from "../../../store";
import { Event } from "../../../interfaces/events";
import { FoundPlace } from "../../../utils/packages/searchEngine/interfaces";
import { getDate, getKeplerSelector } from "../../../utils/tools";
import { useStateWithCallback } from "../../../hooks/useStateWithCallback";
import { EDITOR_MODE_DATA_LAYER_ID, EVENTS_DATA_LAYER_ID } from "../../keplergl/keplerMapUtils";
import { setFilterActionCreator } from "../search/reducer";
import { setEditorModeActionCreator } from "../../keplergl/reducers/editorModeReducer";
import { disableSelectedFeature } from "../../keplergl/reducerExtensions/keplerReduxExtension";
import { Swipe } from "./components/swipe";
import { EventItem } from "./components/eventItem";
import { createProxyArray } from "../../../utils/packages/proxyCreator";
import {
  KeplerDataListener,
  UPDATE_EDITOR_MODE_INDEXES,
  UPDATE_KEPLER_FILTERED_INDEXES,
} from "./packages/keplerDataListener";

type simpleFunc = () => void;
interface EventsProps {
  region: [string, number, string, string | undefined] | null; // [regionName -> ("country, city, province, district), firstFoundIndex, filterName, description]
  fullModeCallback: (enable: boolean) => void;
  open: { open: boolean };
  onSelectEvent?: (eventObj: any) => void;
}

const CLOSE_MODE = "Close";
const OPEN_MODE = "Open";
const FULL_OPEN_MODE = "FullOpen";

const genStorageHash = (): string => {
  let hash: number[] = [];
  for (let i = 0; i < 25; i++) {
    hash.push(Math.floor(Math.random() * 255));
  }

  const textDecoder = new TextDecoder();
  return textDecoder.decode(new Uint8Array(hash));
};

const ITEM_HEIGHT = 58;

export const Events = ({ open, region, fullModeCallback, onSelectEvent }: EventsProps) => {
  const eventsContainer = useRef<HTMLDivElement>(null);
  const {
    searchEngine,
    filteredIndexes,
    isReady: searchEngineIsReady,
  } = useSelector((state: RootState) => state.searchEngine);
  const editorModeMapSelector = useSelector((state: RootState) => state.editorModeMapReducer);
  const [openMode, setOpenMode] = useStateWithCallback<string>(CLOSE_MODE);
  const [storageIsReady, setStorageIsReady] = useState(false);
  const [storageSign, setStorageSign] = useState<string>("");
  const [currentLayerId, setCurrentLayerId] = useState<string>(EVENTS_DATA_LAYER_ID);
  const [storage, setStorage] = useState<any>();
  const [events, setEvents] = useState<any[]>([]);
  const [swipeLock, setSwipeLock] = useState<boolean>(false);
  const dispatch = useDispatch();

  const keplerDataListener = useMemo(() => {
    const keplerListener = new KeplerDataListener();
    keplerListener.startListen();
    return keplerListener;
  }, []);

  const loadChunks = useMemo(() => {
    if (!storage) {
      return;
    }

    let regionData: FoundPlace | null = null;
    if (region) {
      regionData = {
        name: region[0],
        firstFoundIndex: region[1],
        filterName: region[2],
        index: 0,
      };
    }
    const eventsGenerator = searchEngine.searchEvents(regionData, 20, storage);
    const getEventsChunks = eventsGenerator();
    setStorageSign(genStorageHash());
    setEvents([]);

    return () => {
      const { value, done } = getEventsChunks.next();

      if (done) {
        return;
      }
      return value;
    };
  }, [storage]);

  useEffectWithMemory<boolean>(
    (save, getLast, softGetLast) => {
      if (softGetLast() !== null && searchEngine.filters.onlyEventsMapFrame) {
        /*
         When the user enables onlyEventsMapFrame when editor mode is enabled.
         We need to clear the drawn features and turn off the editor mode.
        */
        batch(() => {
          dispatch(setEditorModeActionCreator(false));
          dispatch(disableSelectedFeature());
        });
        setCurrentLayerId(EVENTS_DATA_LAYER_ID);
        getLast(); // this need to clean the store inside useEffectWithMemory hook
        return;
      }

      if (editorModeMapSelector.editorMode) {
        /*
          It is necessary to save the last state of onlyEventsMapFrame so that the user, after turning off the editor mode,
          can automatically return to the previous state of onlyEventsMapFrame
        */
        setCurrentLayerId(EDITOR_MODE_DATA_LAYER_ID);
        save(searchEngine.filters.onlyEventsMapFrame);
        dispatch(setFilterActionCreator({ ...searchEngine.filters, onlyEventsMapFrame: false }));
      } else {
        const lastData = getLast();
        // if lastDate is null, we don't need to go back to the previous state because there was no previous state
        if (lastData === null) {
          return;
        }
        // Here we returned to the previous state of onlyEventsMapFrame.
        setCurrentLayerId(EVENTS_DATA_LAYER_ID);
        dispatch(setFilterActionCreator({ ...searchEngine.filters, onlyEventsMapFrame: lastData }));
      }
    },
    [searchEngine.filters.onlyEventsMapFrame, editorModeMapSelector.editorMode]
  );

  const setKeplerFilteredIndexes = useMemo(() => {
    const keplerSelector = getKeplerSelector();
    const keplerFilteredIndexes = keplerDataListener.getKeplerFilteredIndexes(keplerSelector, currentLayerId);

    let indexes = keplerFilteredIndexes.slice();
    const debounceFunc = debounce(() => {
      const [proxyArray, _] = createProxyArray<any>(searchEngine.proxyData, indexes);
      setStorage(proxyArray.slice());
      setStorageIsReady(true);
    }, 150);

    return (newIndexes: number[]) => {
      indexes = newIndexes;
      debounceFunc();
    };
  }, [searchEngine.filters.onlyEventsMapFrame, editorModeMapSelector, currentLayerId]);

  useEffect(() => {
    /*
      Here we update storage by indexes,
      this need because we don't filter everything that we need by self-made SearchEngine. (JS have bad performance)
      Instead of we use the default keplerFilters for task which need more performance because the kepler use GPU for fast filtering.
      And here we only apply already filtered items by their indexes
    */

    const keplerSelector = getKeplerSelector();
    const keplerFilteredIndexes = keplerDataListener.getKeplerFilteredIndexes(
      keplerSelector,
      editorModeMapSelector.editorMode ? EDITOR_MODE_DATA_LAYER_ID : EVENTS_DATA_LAYER_ID
    );
    setKeplerFilteredIndexes(keplerFilteredIndexes);
    const unsubscribe = keplerDataListener.listener.subscribe(
      editorModeMapSelector.editorMode ? UPDATE_EDITOR_MODE_INDEXES : UPDATE_KEPLER_FILTERED_INDEXES,
      (type: string, indexes: any) => {
        if (type !== "data") {
          return;
        }
        setKeplerFilteredIndexes(indexes);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [region, filteredIndexes, searchEngine.filters.onlyEventsMapFrame, editorModeMapSelector]);

  useEffect(() => {
    setOpenMode(open.open ? OPEN_MODE : CLOSE_MODE);
  }, [region, open]);

  useEffect(() => {
    fullModeCallback(openMode === FULL_OPEN_MODE);
  }, [openMode]);

  useEffect(() => {
    if (!loadChunks) {
      return;
    }
    const chunk = loadChunks();

    if (chunk) {
      setEvents(chunk);
    }
  }, [storageSign]);

  const checkIfEventLoaded = (index: number): boolean => {
    return index < events.length;
  };

  const loadChunkOfEvents = () => {
    if (!loadChunks) {
      return;
    }

    const chunk = loadChunks();
    if (chunk) {
      setEvents(events.concat(chunk));
    }
  };

  const hideWindow = (callback?: simpleFunc) => {
    setOpenMode(OPEN_MODE, callback);
  };

  const closeWindow = (callback?: simpleFunc) => {
    setOpenMode(CLOSE_MODE, callback);
  };

  const openWindow = (callback?: simpleFunc) => {
    setOpenMode(FULL_OPEN_MODE, callback);
  };

  const processRowDataToEvent = (row: any[]): Event => {
    let obj: any = {};
    for (let key in searchEngine.currentFields) {
      const i = searchEngine.currentFields[key];
      obj[key] = row[i];
    }
    return obj as Event;
  };

  const onScrollHandler = (scrollOffset: number) => {
    if (!eventsContainer.current) {
      return;
    }
    if (scrollOffset === 0) {
      setSwipeLock(false);
    } else {
      setSwipeLock(true);
    }
  };

  const swipeCallback = (offset: number, callback: simpleFunc) => {
    if (openMode === OPEN_MODE) {
      if (offset < 0) {
        openWindow(callback);
      } else {
        closeWindow(callback);
      }
    } else {
      hideWindow(callback);
    }
  };

  const getEventsCount = (): number => {
    return !storage ? 0 : storage.length;
  };

  const isReady = useMemo(() => searchEngineIsReady && storageIsReady, [searchEngineIsReady, storageIsReady]);

  return (
    <div ref={eventsContainer} className={cn(styles.regionEventsContainer, styles[`regionEventsContainer${openMode}`])}>
      <div className={styles.regionEventsItems}>
        <Swipe
          swipeLock={swipeLock}
          target={eventsContainer.current}
          readyCallBack={swipeCallback}
          almostOpen={openMode === OPEN_MODE}
        />
        <button
          className={cn(
            styles.regionEventsItemsButton,
            openMode === FULL_OPEN_MODE && styles.regionEventsItemsButtonClose
          )}
        />
        {!isReady && (
          <AbsoluteLoading>
            <span>Events Loading...</span>
          </AbsoluteLoading>
        )}
        {getEventsCount() === 0 && isReady && (
          <div className={styles.noDataWrapper}>
            <span className={styles.noDataText}>Events not found</span>
          </div>
        )}
        <InfiniteLoader isItemLoaded={checkIfEventLoaded} loadMoreItems={loadChunkOfEvents} itemCount={Infinity}>
          {({ onItemsRendered, ref }) => (
            <AutoSizer ref={ref}>
              {({ height, width }) => {
                return (
                  <FixedSizeList
                    useIsScrolling={true}
                    height={height}
                    width={width}
                    itemCount={events.length}
                    itemSize={ITEM_HEIGHT}
                    onItemsRendered={onItemsRendered}
                    onScroll={({ scrollOffset }) => onScrollHandler(scrollOffset)}
                  >
                    {({ index, style }) => {
                      const item = events[index];
                      const date = getDate(item[searchEngine.currentFields["verifiedDate"]]);
                      const eventObj = processRowDataToEvent(storage[index]);
                      const description = item[searchEngine.currentFields["description"]];

                      return (
                        <EventItem
                          description={description}
                          date={date}
                          style={style}
                          onSelectEvent={() => {
                            if (onSelectEvent !== undefined) {
                              // hideWindow();
                              onSelectEvent(eventObj);
                            }
                          }}
                        />
                      );
                    }}
                  </FixedSizeList>
                );
              }}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    </div>
  );
};
